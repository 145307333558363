<template>
  <div class="home">
    <Header />

    <TopBanner
      :innertags="innertags"
      :bannerText="bannerText"
      :totalJob="totalJob"
      :total="total"
      :browseTotal="browseTotal"
    />

    <Searchtable
      :innertags="innertags"
      @getBannerText="setBannerText"
      @remoteChecked="setRemoteChecked"
      @tagsList="setTagsList"
    />

    <Tablefilter
      :checked="checked"
      :page_size="page_size"
      :innertags="innertags"
      @getJobsData="setJobsData"
      :tagsList="tagsList"
    />

    <MiddleContent
      class="hidesection"
      greeting-message="Remote Web3 Jobs"
      links1="Backend Remote Web3 Jobs"
      links2="Customer Support Remote Web3 Jobs"
      links3="Defi Remote Web3 Jobs"
      links4="Design Remote Web3 Jobs"
      links5="Dev Entry Level Remote Web3 Jobs"
      links6="Dev Remote Web3 Jobs"
      links7="Front End Remote Web3 Jobs"
      links8="Full Stack Remote Web3 Jobs"
      links9="Golang Remote Web3 Jobs"
      links10="Javascript Remote Web3 Jobs"
      links11="Junior Dev Remote Web3 Jobs"
      links12="Mobile Remote Web3 Jobs"
      links13="Nft Remote Web3 Jobs"
      links14="React Remote Web3 Jobs"
      links15="Remote Smart Contract Web3 Jobs"
      links16="Remote Solidity Web3 Jobs"
      links17="Remote Tech Lead Web3 Jobs"
      links18="Rust Remote Web3 Jobs"
      links19="Solana Remote Web3 Jobs"
      links20="Smart Contract Remote Web3 Jobs"
    />

    <MiddleContent
      class="hidesection"
      greeting-message="Remote Non-Tech Web3 Jobs"
      links1="Community Manager Remote Web3 Jobs"
      links2="Copywriting Remote Web3 Jobs"
      links3="Social Media Remote Web3 Jobs"
      links6="HR Remote Web3 Jobs"
      links7="Marketing Remote Web3 Jobs"
      links8="Designer Remote Web3 Jobs"
      links11="Product Manager Remote Web3 Jobs"
      links12="Project Manager Remote Web3 Jobs"
      links13="Artist Remote Web3 Jobs"
      links16="Recruiter Remote Web3 Jobs"
      links17="Sales Remote Web3 Jobs"
      links18="Finance Remote Web3 Jobs"
    />

    <MiddleContentJob
      class="hidesection"
      greeting-message="Web3 Developer Salaries"
      links1="Solidity Developer Salaries"
      links2="Blockchain Developer Salaries"
      links3="Smart Contract Developer Salaries"
      links4="Backend Developer Salaries"
      links5="CTO Developer Salaries"
      links6="Full Stack Developer Salaries"
      links7="Lead Developer Salaries"
      links8="Senior Developer Salaries"
      links9="Junior Developer Salaries"
      links10="Architecture Developer Salaries"
      links11="Golang Developer Salaries"
      links12="React Developer Salaries"
      links13="Node.js Developer Salaries"
      links14="Java Developer Salaries"
      links15="Mobile Developer Salaries"
    />

    <MiddleContentJob
      class="hidesection"
      greeting-message="Web3 Non-Tech Salaries"
      links1="Communty Manager Salaries"
      links2="Marketing Salaries"
      links3="Sales Salaries"
      links4="Product Manager Salaries"
      links6="Project Manager Salaries"
      links7="Social Media Salaries"
      links8="Design Salaries"
      links9="Legal Salaries"
      links11="Finance Salaries"
      links12="Lead Salaries"
      links13="Senior Salaries"
      links14="Junior Salaries"
    />

    <MiddleContent
      class="hidesection"
      greeting-message="Top Web3 Cities"
      links1="Web3 Jobs in Amsterdam"
      links2="Web3 Jobs in Atlanta"
      links3="Web3 Jobs in Austin"
      links4="Web3 Jobs in Bangkok"
      links5="Web3 Jobs in Berlin"
      links6="Web3 Jobs in Boston"
      links7="Web3 Jobs in Chicago"
      links8="Web3 Jobs in Dubai"
      links9="Web3 Jobs in Dublin"
      links10="Web3 Jobs in Hong Kong"
      links11="Web3 Jobs in Lisbon"
      links12="Web3 Jobs in London"
      links13="Web3 Jobs in Los Angeles"
      links14="Web3 Jobs in New York"
      links15="Web3 Jobs in San Francisco"
      links16="Web3 Jobs in Singapore"
      links17="Web3 Jobs in Sydney"
      links18="Web3 Jobs in Tel Aviv"
      links19="Web3 Jobs in Toronto"
      links20="Web3 Jobs in Vancouver"
    />

    <MiddleContent
      class="hidesection"
      greeting-message="Regions"
      links1="Southeast Asia"
      links2="Eastern Europe"
      links3="Central America"
      links6="East Asia"
      links7="Northern Africa"
      links8="South America"
      links11="South Asia"
      links12="Southern Africa "
      links13="Middle East"
      links16="Western Europe"
      links17="North America"
      links18="Oceania"
    />

    <Footer />
  </div>
</template>

<script>

import Header from '@/components/Header.vue';
import TopBanner from '@/components/TopBanner.vue';
import MiddleContent from '@/components/MiddleContent.vue';
import MiddleContentJob from '@/components/MiddleContentJob.vue';
import Footer from '@/components/Footer.vue';
import Searchtable from '@/components/Searchtable.vue';
import Tablefilter from '@/components/Tablefilter.vue';
import Singlejob from '@/components/Singlejob.vue';


import './style.css';


export default {
  name: 'tags',
 metaInfo() {
    let remote = this.checked ? 'Remote' : '';
    let noindex = this.total == 0 ? true : false;
        return {
          title: this.bannerText + ' ' + '(' + this.currentDate + ')' + ' ' +`– Natives`,
      meta: [
        {
          name: 'robots',
          content: noindex ? 'noindex' : 'index, follow' 
        },
        {
          name: 'description',
          content: 'Browse' + ' ' + this.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' ' + (this.bannerText + ' ' + 'in' + ' ' + this.currentDate + ' ' + 'with salaries from' + ' $' + this.numFormatter(Math.round(this.minSalary)) + ' to' + ' $' + this.numFormatter(Math.round(this.maxSalary)) + ' per year at companies like' + ' ' + this.companyList.join(", and ").replace(', and ', ', ')) + '.'
        },
        {
          property: 'og:title',
          content: this.bannerText + '(' + this.currentDate + ')' + ' ' +`– Natives`
        },
        {
          property: 'og:description',
          content: 'Browse' + ' ' + this.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' ' + (this.bannerText + ' ' + 'in' + ' ' + this.currentDate + ' ' + 'with salaries from' + ' $' + this.numFormatter(Math.round(this.minSalary)) + ' to' + ' $' + this.numFormatter(Math.round(this.maxSalary)) + ' per year at companies like' + ' ' + this.companyList.join(", and ").replace(', and ', ', ')) + '.'
        },
        {
          name: 'twitter:title',
          content: this.bannerText + ' Jobs in Web3 ' + '(' + this.currentDate + ')' + ' ' +`– Natives`
        },
        {
          name: 'twitter:description',
          content: 'Browse' + ' ' + this.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' ' + (this.bannerText + ' ' + 'in' + ' ' + this.currentDate + ' ' + 'with salaries from' + ' $' + this.numFormatter(Math.round(this.minSalary)) + ' to' + ' $' + this.numFormatter(Math.round(this.maxSalary)) + ' per year at companies like' + ' ' + this.companyList.join(", and ").replace(', and ', ', ')) + '.'
        },
        
        
      ]
    }
        },
  components: {
    Header,
    TopBanner,
    MiddleContent,
    MiddleContentJob,
    Footer,
    Searchtable,
    Tablefilter,
    Singlejob
  },
  data(){
	return{
		bannerText:'',
		totalJob:520,
		total:0,
		browseTotal:0,
        innertags:true,
		page_size:35,
		checked:false,
		tagsList:[],
    currentDate:this.getDate(),
    minSalary: 0,
    maxSalary: 0,
    companyList:[]
	}
  },
  watch:{
    $route (to, from){
        this.$metaInfo;
    }
  },
  methods:{
    setTagsList(items){
		this.tagsList = items;
	}, 
	setBannerText(val){
		this.bannerText = val;
    let htmlData = "";
				if(this.checked == true){
					htmlData = "Remote ";
				}
				htmlData += this.tagsList.join(" ");
				if(this.tagsList.length == 0){
					htmlData += " Web3 Jobs";
				}else if(this.tagsList.length == 1){
					htmlData += " Jobs in Web3";
				}else{
					htmlData += " Jobs";
				}
        this.$emit('getBannerText',htmlData); 
        //console.log(val);
	},
	setRemoteChecked(val){
		this.checked = val;
	},
	setJobsData(val){
		//console.log("val", val);
		this.total = val.total;
		this.browseTotal = val.total_companies;
    val.jobs.forEach((obj,index)=>{
      if(index <=2){
      this.companyList.push(obj.company_name)
      if(this.minSalary == 0 && obj.salary_min != null)
      this.minSalary = obj.salary_min;
      }
      if(this.minSalary > obj.salary_min && obj.salary_min != null){
        this.minSalary=obj.salary_min
      }
    if(this.maxSalary < obj.salary_max){
      this.maxSalary=obj.salary_max
      }
    })
	
	},
  numFormatter(num) {
      if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
      } else if (num > 1000000) {
        return (num / 1000000).toFixed(0) + "M"; // convert to M for number from > 1 million
      } else if (num < 900) {
        return num; // if value < 1000, nothing to do
      }
    },
  getDate(){
    let d = new Date;
    let array=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    return array[d.getMonth()] + ' ' + d.getFullYear()

  }
  }
}
</script>

<style scoped>
.switch-toggle span svg.bi-brightness-high-fill.b-icon,
.switch-toggle.switch-toggle-checked svg.bi-moon.b-icon {
  display: none;
}

.switch-toggle.switch-toggle-checked span svg.bi-brightness-high-fill.b-icon {
  display: block;
  color: #fff;
}
.switch-toggle span svg {
  color: #283618;
  transform: rotate(220deg);
}
</style>