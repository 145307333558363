<template>
  <div class="jobpage">
    <Header />

    <div class="Main_Jobdetails_page">
    <Singlejobpage />
    </div>

    <MiddleContent greeting-message="Remote Web3 Jobs" 
    links1="Backend Remote Web3 Jobs" 
    links2="Customer Support Remote Web3 Jobs" 
    links3="Defi Remote Web3 Jobs" 
    links4="Design Remote Web3 Jobs" 
    links5="Dev Entry Level Remote Web3 Jobs" 
    links6="Dev Remote Web3 Jobs" 
    links7="Front End Remote Web3 Jobs" 
    links8="Full Stack Remote Web3 Jobs" 
    links9="Golang Remote Web3 Jobs" 
    links10="Javascript Remote Web3 Jobs" 
    links11="Junior Dev Remote Web3 Jobs" 
    links12="Mobile Remote Web3 Jobs" 
    links13="Nft Remote Web3 Jobs" 
    links14="React Remote Web3 Jobs" 
    links15="Remote Smart Contract Web3 Jobs" 
    links16="Remote Solidity Web3 Jobs" 
    links17="Remote Tech Lead Web3 Jobs" 
    links18="Rust Remote Web3 Jobs" 
    links19="Solana Remote Web3 Jobs" 
    links20="Smart Contract Remote Web3 Jobs" 
    />


    <MiddleContent greeting-message="Remote Non-Tech Web3 Jobs" 
    links1="Community Manager Remote Web3 Jobs" 
    links2="Copywriting Remote Web3 Jobs" 
    links3="Social Media Remote Web3 Jobs" 
    
    links6="HR Remote Web3 Jobs" 
    links7="Marketing Remote Web3 Jobs" 
    links8="Designer Remote Web3 Jobs" 
  
    links11="Product Manager Remote Web3 Jobs" 
    links12="Project Manager Remote Web3 Jobs" 
    links13="Artist Remote Web3 Jobs" 

    links16="Recruiter Remote Web3 Jobs" 
    links17="Sales Remote Web3 Jobs" 
    links18="Finance Remote Web3 Jobs" 
    />
    


    <MiddleContentJob greeting-message="Web3 Developer Salaries" 
      links1="Solidity Developer Salaries" 
    links2="Blockchain Developer Salaries" 
    links3="Smart Contract Developer Salaries" 
    links4="Backend Developer Salaries" 
    links5="CTO Developer Salaries" 
    links6="Full Stack Developer Salaries" 
    links7="Lead Developer Salaries" 
    links8="Senior Developer Salaries" 
    links9="Junior Developer Salaries" 
    links10="Architecture Developer Salaries" 
    links11="Golang Developer Salaries" 
    links12="React Developer Salaries" 
    links13="Node.js Developer Salaries" 
    links14="Java Developer Salaries" 
    links15="Mobile Developer Salaries"
    />

    <MiddleContentJob greeting-message="Web3 Non-Tech Salaries" 
      links1="Communty Manager Salaries" 
    links2="Marketing Salaries" 
    links3="Sales Salaries" 
    links4="Product Manager Salaries" 

    links6="Project Manager Salaries" 
    links7="Social Media Salaries" 
    links8="Design Salaries" 
    links9="Legal Salaries" 

    links11="Finance Salaries" 
    links12="Lead Salaries" 
    links13="Senior Salaries" 
    links14="Junior Salaries" 
    />


    <MiddleContent greeting-message="Top Web3 Cities" 
    links1="Web3 Jobs in Amsterdam" 
    links2="Web3 Jobs in Atlanta" 
    links3="Web3 Jobs in Austin" 
    links4="Web3 Jobs in Bangkok" 
    links5="Web3 Jobs in Berlin" 
    links6="Web3 Jobs in Boston" 
    links7="Web3 Jobs in Chicago" 
    links8="Web3 Jobs in Dubai" 
    links9="Web3 Jobs in Dublin" 
    links10="Web3 Jobs in Hong Kong" 
    links11="Web3 Jobs in Lisbon" 
    links12="Web3 Jobs in London" 
    links13="Web3 Jobs in Los Angeles" 
    links14="Web3 Jobs in New York" 
    links15="Web3 Jobs in San Francisco" 
    links16="Web3 Jobs in Singapore" 
    links17="Web3 Jobs in Sydney" 
    links18="Web3 Jobs in Tel Aviv" 
    links19="Web3 Jobs in Toronto" 
    links20="Web3 Jobs in Vancouver" 
    />


    <MiddleContent greeting-message="Regions" 
    links1="Southeast Asia" 
    links2="Eastern Europe" 
    links3="Central America" 
    
    links6="East Asia" 
    links7="Northern Africa" 
    links8="South America" 
  
    links11="South Asia" 
    links12="Southern Africa " 
    links13="Middle East" 

    links16="Western Europe" 
    links17="North America" 
    links18="Oceania" 
    />

    <Footer />

    <div class="selected_list">
        <b-container>
          <div class="selected_list_body">
            <div class="leftside_list">
              <div class="logo_singlejob">
                <img src="../assets/Job_ Logo1.png" />
              </div>
              <div>
                  <div class="table_jobdetails">
                      <h4>Lead Project Manager</h4>
                      <p>Coinbase Inc.</p>
                      <p class="amoutlist amountdflex"> <span>$150k - $180k</span> <span class="Remotetext">Remote</span></p>
                  </div>      
              </div>
            </div>
            
            <div class="rightside_list">
              <div class="centertag_list">
                 <span class="remotedata">
                      <ul class="tagsinnerlisting">
                          <li class="Tagslist_li">Javascript</li>
                          <li class="Tagslist_li">Entry Level</li>
                          <li class="Tagslist_li">Solidity</li>
                          <li class="Tagslist_li">Smart Contracts</li>
                          <li class="Tagslist_li">Design</li>
                      </ul>
                  </span>    
              </div>
              <div class="bottamhourscounting"><p>16h</p></div>
              <div class="btnapply_fixed">
                 <span class="applybutton_main">
                      <b-button class="btn btn_apply">Apply Now</b-button>
                  </span>     
              </div>
            </div>
          </div>
        </b-container>
    </div>
    
  </div>
</template>

<script>

import Header from '@/components/Header.vue';
import MiddleContent from '@/components/MiddleContent.vue';
import MiddleContentJob from '@/components/MiddleContentJob.vue';
import Footer from '@/components/Footer.vue';


import './style.css';
import Singlejobpage from '../components/Singlejobpage.vue';

export default {
  name: 'Jobdetails',
  components: {
    Header,
    MiddleContent,
    MiddleContentJob,
    Footer,
    Singlejobpage
  },
  data: {
    scrollPosition: null
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  }
 
}
</script>

