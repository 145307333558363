<template>
  <div class="header">
    <b-container>
        <b-navbar toggleable="lg" type="dark" class="header_bgcolor">
          <b-navbar-brand href="/">
            <img src="../assets/natives-logo-light.png" fluid alt="Fluid image" />
            <span>Beta</span>
          </b-navbar-brand>
          
          <b-collapse id="nav-collapse" is-nav class="marginheading_middle">
            <b-navbar variant="faded" type="light">
            <b-nav>
                <b-nav-item active>Salaries</b-nav-item>
                <b-nav-item>Internships</b-nav-item>
                <b-nav-item>Learn Web3</b-nav-item>
                <b-nav-item>Highest Paid</b-nav-item>
                <b-nav-item>Most Popular</b-nav-item>
              </b-nav>
            </b-navbar>
            
          </b-collapse>
           <b-navbar-nav class="ml-auto">
              <b-button class="PostaJob"> Post a Job </b-button>
            </b-navbar-nav>
            <b-navbar-toggle target="nav-collapse" @click="addBodyClass" v-bind:class="{ active: isActive }"></b-navbar-toggle>
        </b-navbar>
    </b-container>

  </div>
</template>

<script>
let myBody = document.getElementsByTagName('body')[0];
import "./style.css";
export default {
  name: "Header",
   data() {
    return {
	isActive: false
  }
  },
	mounted(){
	if(typeof this.$route.path  !== "undefined"){
		let path = this.$route.path;
		if(path != "/"){
		let remote = 0;
		if(path.includes("+remote-")){
			remote = 1;
			path = path.replace("+remote-","-");
		}else if(path.includes("-remote-")){
			remote = 2;
			path = path.replace("-remote-","-");
		}else if(path.includes("-remote+")){
			remote = 3;
			path = path.replace("-remote+","-");
		}else if(path.includes("+remote+")){
			remote = 4;
			path = path.replace("+remote+","+");
		}
		if(path.includes("/remote") == false){
			path =  path.replace("/","remote-");
			path =  path.replace("--","-");
		}
		if(remote != 0){
			
				this.$router.push({ path: path, query: {}});
		}
		}
		
	}
  },
  methods: {
        addBodyClass: function(){
            this.isActive = true;

            // You must manually add & remove classes to the body element
            myBody.classList.toggle('addclassbody');
            
        },
      //  removeBodyClass: function(){
      //       this.isActive = false;
      //       myBody.classList.remove('is-active');
      //   }
  }
};
</script>
