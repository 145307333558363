<template>
  <div :class="innertags ? 'Searchtable_tags' : 'Searchtable'">
    <b-container >
        <b-row>
            <b-col lg="12">
                <div class="tablesearch_information">
                    <!-- <div class="searchtable_field">
                        <b-input-group size="sm" class="mb-2">
                            <b-input-group-prepend is-text>
                                 <img src="../assets/searchicon.svg" />
                            </b-input-group-prepend>
                            <b-form-input type="search" v-model="search" placeholder="Tag, Location, Company"></b-form-input>
                        </b-input-group>
                    </div> -->

                    <Searchkeyword :query="query"   />

                     <div class="switcher_info">
                        <!-- <b-form-checkbox v-model="checked" name="check-button" @change="handleJobFilter" switch> -->
                        <b-form-checkbox id="checkbox" v-model="checked" name="check-button" @change="handleJobFilter" switch>
                        Remote
                        <!-- <b>(Checked: {{ checked }})</b> -->
                        </b-form-checkbox>
                    </div>
                </div>

            </b-col>
            <b-col lg="12">
                <div class="Tagslist_main">
                   
                    <div class="searchBtn">
                        <b-form-group>
                             <b-link v-for="tags in tagsItem" :href="convertToURL(tags.key)" :key="tags.id" @click="handleJobFilters" :class="tagsItemList.includes(tags.value) ? 'btn btn-secondary active' : 'btn btn-secondary'">{{tags.key}}</b-link>
                        </b-form-group>
                   
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
  </div>
</template>


<script>
import Searchkeyword from './Searchkeyword.vue';
import { VueAutosuggest } from 'vue-autosuggest';

import "./style.css";
export default {
    props:{
        innertags:Boolean
    },
  name: "Searchtable",
  components: {
    Searchkeyword,
    VueAutosuggest
  },
  data() {
      return {
        query: '',
        checked: false,
        myToggle: false,
        search: "",
        selected: '',
		tagsList:[],
		tagsItemList:[],
        selectedTags: '',
		slug:'',
        options: [
          { text: 'Frontend', value: 'Frontend' },
          { text: 'Analyst', value: 'Analyst' },
          { text: 'Backend', value: 'Backend', },
          { text: 'Blockchain', value: 'Blockchain' },
          { text: 'CTO', value: 'CTO' },
          { text: 'Community Manager', value: 'Community Manager', },
          { text: 'Devops', value: 'Devops' },
          { text: 'Customer Support', value: 'Customer Support' },
          { text: 'GoLang', value: 'GoLang', },
          { text: 'Intern', value: 'Intern' },
          { text: 'Marketing', value: 'Marketing' },
          { text: 'Product Manager', value: 'Product Manager' },
          { text: 'Javascript', value: 'Javascript' },
          { text: 'Entry Level', value: 'Entry Level' },
          { text: 'Solidity', value: 'Solidity', },
          { text: 'Design', value: 'Design' },
          { text: 'Smart Contracts', value: 'Smart Contracts' },
          { text: 'Rust', value: 'Rust' }
        ],
		tagsItem :[{key:'Analyst', value:'analyst'},
		{key:'Backend', value:'backend'},
		{key:'Blockchain', value:'blockchain'},
		{key:'Community Manager', value:'community-manager'},
		{key:'DAO', value:'dao'},
		{key:'DeFi', value:'defi'},
		{key:'Design', value:'design'},
		{key:'DevOps', value:'devops'},
		{key:'Discord', value:'discord'},
		{key:'Front End', value:'front-end'},
		{key:'Full Stack', value:'full-stack'},
		{key:'Intern', value:'intern'},
		{key:'Marketing', value:'marketing'},
		{key:'Moderator', value:'moderator'},
		{key:'NFT', value:'nft'},
		{key:'Product Manager', value:'product-manager'},
		{key:'Smart Contract', value:'smart-contract'},
		{key:'Solana', value:'solana'},
		{key:'Solidity', value:'solidity'},
		{key:'Web3js', value:'web3js'}
		]
      }
    },
	watch:{
		$route (to, from){
			this.handleFormAction();
			
		}
	},
	mounted() {
			this.handleFormAction();
	},
	methods:{
		handleFormAction(){
			if(this.$route.path  != '/'){
			
				let pathURL = this.$route.path;
				if(pathURL.includes("/remote") != false && this.checked == false){
					this.checked = true;
				}
				this.tagsList = [];
				this.tagsItemList = [];
				this.query='';
				
				//let pathURL = this.$route.path;
				 pathURL=pathURL.replace("/","");
				 pathURL=pathURL.replace("remote-","");
				 pathURL=pathURL.replace("-web3-jobs","");
				 pathURL=pathURL.replace("web3-jobs","").split("+");
				 
				this.tagsList = [];
				pathURL.forEach(element =>{
					if(element != ""){
					if(pathURL.includes(element)){
						this.selectedTags = element;
						this.query = element;
					}			
						this.tagsItemList.push(element);
						this.tagsList.push(this.makeTitle(element));
					}
				});
				/*
				this.tagsItem.forEach(element =>{
					if(pathURL.includes(element.value)){
						this.selectedTags = element.value;
						this.query = element.key;
						this.tagsList.push(element.key);
					}
				});*/
				let htmlData = "";
				if(this.checked == true){
					htmlData = "Remote ";
				}
				htmlData += this.tagsList.join(" ");
				if(this.tagsList.length == 0){
					htmlData += " Web3 Jobs";
				}else if(this.tagsList.length == 1){
					htmlData += " Jobs in Web3";
				}else{
					htmlData += " Jobs";
				}

				if(this.checked == false && this.query == ''){
					htmlData = "";
				}
				//alert("htmlData1",htmlData);
				this.$emit('getBannerText',htmlData); 
				this.$emit('remoteChecked',this.checked); 
				this.$emit('tagsList',this.tagsList); 
			}
		},
		makeTitle(slug) {
		  var words = slug.split('-');

		  for (var i = 0; i < words.length; i++) {
			var word = words[i];
			words[i] = word.charAt(0).toUpperCase() + word.slice(1);
		  }

		  return words.join(' ');
		},
		convertToURL(Text) {
		
		  let url =  "/";
		  if(this.checked == true){
			url = "remote-";
		  }
		  url +=Text.trim().toLowerCase()
					 .replace(/ /g, '-')
					 .replace(/[^\w-]+/g, '')+"-web3-jobs";
					 return url;
		},
        handleJobFilters(event){
			event.preventDefault();
            let slugUrl  = this.convertToSlug(event.target.innerHTML);
			this.slug = slugUrl;
			if(slugUrl.length > 0 && this.checked == true){
				slugUrl = "remote-"+this.slug;
			}else{
				slugUrl = this.slug;
			}
			if(slugUrl.includes("remote-remote")){
				slugUrl = slugUrl.replace("remote-remote", "remote");
			}
			this.$router.push({ path: '/'+slugUrl+"-web3-jobs", query: { }})  ;
			console.log(slugUrl);
        },
		convertToSlug(Text) {
		  return Text.trim().toLowerCase()
					 .replace(/ /g, '-')
					 .replace(/[^\w-]+/g, '');
		},
		handleJobFilter(){
            //this.query=this.selected;
		
			let htmlData = "";
			if(this.checked == true){
				htmlData = "Remote ";
			}
			htmlData += this.tagsList.join(" ");
			if(this.tagsList.length == 0){
					htmlData += " Web3 Jobs";
			}else if(this.tagsList.length == 1){
					htmlData += " Jobs in Web3";
			}else{
				htmlData += " Jobs";
			}
			
            if(this.checked == false && this.query == ''){
				htmlData = "";
			}
			
			this.$emit('getBannerText',htmlData); 
			this.$emit('remoteChecked',this.checked);
			
			let slugUrl  = this.convertToSlug(this.query);
			if(this.$route.path  != '/' && this.$route.path!= '/remote-web3-jobs'){
				slugUrl = this.$route.path;
				slugUrl =slugUrl.replace("/", "");
				slugUrl =slugUrl.replace("-web3-jobs", "");
				if(this.checked == false){
					slugUrl =slugUrl.replace("remote-", "");
				}
				
			}
			if( this.$route.path == "/web3-jobs"){ slugUrl = "";}			
			if(this.checked == true && slugUrl != ''){
				slugUrl = "remote-"+slugUrl+"-web3-jobs";
			}else if(this.checked == true && slugUrl == ''){
				slugUrl = "remote-web3-jobs";
			}else if(this.checked == false && slugUrl != ''){
				slugUrl = slugUrl+"-web3-jobs";
			}
			
			if(slugUrl.includes("remote-remote")){
				slugUrl = slugUrl.replace("remote-remote", "remote");
			}
			
			this.$router.push({ path: '/'+slugUrl, query: { }})  ;	
			
			//alert(this.query);
		
		}
		
	}
};
</script>

