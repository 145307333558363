<template>
  <div class="demo searchkeyword_mian">
    <div class="autosuggest-container">
      <div class="searchicon"><img src="../assets/searchicon.svg" /></div>
    
      <vue-autosuggest
        v-model="localInput"
        :suggestions="suggestions"
        
       
		@keyup="fetchResults"
        
        @selected="onSelected"
        :get-suggestion-value="getSuggestionValue"
        :input-props="{id:'autosuggest__input', placeholder:'Tag, Location, Company'}" 
        search > 
        <div slot-scope="{suggestion}" style="display: flex; align-items: center;" @click="clickHandler">
          <div style="{ display: 'flex', color: 'navyblue'}" >{{suggestion.item.name}}</div>
        
        </div>
      </vue-autosuggest>
    </div>
  </div>
</template>

<script>
import { VueAutosuggest } from "vue-autosuggest";
import "./style.css" ;
import axios from "axios";
export default {
  components: {
    VueAutosuggest
  },

  props: {
    query: String
  },

  data() {
    return {
      selected: "",
      search: "",
	  cancelSource:false,
	  localInput:'',
      suggestions: [
            { id: 1, name: "Frontend" },
            { id: 2, name: "Analyst" },
            { id: 3, name: "Backend" },
            { id: 4, name: "Blockchain" },
            { id: 5, name: "CTO" },
            { id: 6, name: "Community Manager" },
            { id: 7, name: "Devops" },
            { id: 8, name: "Customer Support" },
            { id: 9, name: "GoLang" },
            { id: 10, name: "Intern" },
         
      ]
    };
  },
  watch:{
		$route (to, from){
			 if(this.$route.path  != '/'){
			 
				   let pathURL = this.$route.path;
				   pathURL=pathURL.replace("/","");
				   pathURL=pathURL.replace("remote-","");
				   pathURL=pathURL.replace("-web3-jobs","");
				   pathURL=pathURL.replace("web3-jobs","").split("+");
				let tagsList = [];
					
					pathURL.forEach(element =>{
						
						tagsList.push(this.makeTitle(element));
					});
					this.localInput = tagsList.join(" ");
			 }
			
			
		}
	},
  mounted() {
	let obj=this;
	setTimeout(function(){
		if(obj.$route.path  != '/'){
			 
				   let pathURL = obj.$route.path;
				   pathURL=pathURL.replace("/","");
				   pathURL=pathURL.replace("remote-","");
				   pathURL=pathURL.replace("-web3-jobs","");
				   pathURL=pathURL.replace("web3-jobs","").split("+");
					let tagsList = [];
					
					pathURL.forEach(element =>{
						
						tagsList.push(obj.makeTitle(element));
					});
					obj.localInput = tagsList.join(" ");
			 }
	},1500);
        //this.localInput = this.query
  },
  computed: {
    filteredOptions() {
      let dataObj= [
        { 
          data: this.suggestions[0].data.filter(option => {
            return option.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
          })
        }
      ];
	  console.log("dataObj",dataObj);
	  return dataObj;
    }
  },
  methods: {
	formSubmit(id = ''){
		
		if(id == ''){
			id = document.getElementById("autosuggest__input").value;
		}
		
		let slugUrl  = this.convertToSlug(id);
		if(slugUrl.length == 0 ){ return;}
		if(document.getElementById("checkbox").checked == true){
			slugUrl = "remote-"+slugUrl;
		}
		
		if(slugUrl.includes("remote-remote")){
				slugUrl = slugUrl.replace("remote-remote", "remote");
			}
			this.$router.push({ path: '/'+slugUrl+"-web3-jobs", query: { }})  ;
	},
	makeTitle(slug) {
	  var words = slug.split('-');

	  for (var i = 0; i < words.length; i++) {
		var word = words[i];
		words[i] = word.charAt(0).toUpperCase() + word.slice(1);
	  }

	  return words.join(' ');
	},
	convertToSlug(Text) {
		  return Text.trim().toLowerCase()
					 .replace(/ /g, '-')
					 .replace(/[^\w-]+/g, '');
		},
    clickHandler(item) {
		//alert(1);
    },
    onSelected(item) {
      this.selected = item.item;
	  this.formSubmit(item.item.name);
    },
    onInputChange(text) {
      //console.log(text)
    },

    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    focusMe(e) {
      //console.log(e) 
    },
	async fetchResults(event) {
	if(event.which == 40 || event.which == 38) { return; }
	if(event.which == 13) { this.formSubmit(); return;}
	 this.localInput = event.target.value;
	
      this.cancelSource = axios.CancelToken.source();
	  
	  await axios
        .get(process.env.VUE_APP_API_PATH+'/jobs/suggest?query='+ this.localInput, {
          cancelToken: this.cancelSource.token,
        })
        .then((response) => {
			let suggestionData = [];
            (response.data.data.suggestions).forEach((element,index)=>{
				if(element.type == "tag"){
					suggestionData.push({id:index,name:element.name});
				}
			});
			this.suggestions = [];
			this.suggestions.push({ name: "search", data: suggestionData });
			console.log(this.suggestions)
          //this.loader = false;
		  
        });
      
       
      
    }
  }
}
</script>
