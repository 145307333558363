<template>
  <div class="Footer">
    <b-container >
        <b-row>
            
            <b-col md="3">
                <div class="footerlogo_section">
                     <a href="https://natives.co/" ><img src="../assets/natives-logo-light.png" /> </a>
                     <p>Jobs & Talent for</p>
                     <p>Web3 Natives</p>
                </div>
            </b-col>
            <b-col md="3" class="hidesection">
                <div class="Middle_content_body footerlinks">
                    <ul>
                        <li><a href="#">What is Web3? </a> </li>
                        <li><a href="#">Web3 Companies </a> </li>
                        <li><a href="#">Twitter </a> </li>
                        <li><a href="#">Discord </a> </li>
                        <li><a href="#">Podcast </a> </li>
                    </ul>
                </div>
            </b-col>
            <b-col md="3" class="hidesection">
                <div class="Middle_content_body footerlinks">
                    <ul>
                        <li><a href="#">What is Web3? </a> </li>
                        <li><a href="#">Web3 Companies </a> </li>
                        <li><a href="#">Twitter </a> </li>
                        <li><a href="#">Discord </a> </li>
                        <li><a href="#">Podcast </a> </li>
                    </ul>
                </div>
            </b-col>
            <b-col md="3" class="hidesection">
                <div class="Middle_content_body">
                   <b-button class="PostaJob"> Post a Job </b-button>
                </div>
            </b-col>
        </b-row>
    </b-container>

  </div>
</template>

<script>
import "./style.css";
export default {
  name: "Footer",
}
</script>