<template>
  <div class="middlecontent">
    <b-container >
        <b-row>
            <h3 class="middlecontent_heading"> {{ greetingMessage }}</h3>
            <b-col md="3">
                <div class="Middle_content_body">
                    <ul>
                        <li><a href="#"> {{ links1 }} </a> </li>
                        <li><a href="#"> {{ links2 }}</a> </li>
                        <li><a href="#"> {{ links3 }}</a> </li>
                        <li><a href="#"> {{ links4 }}</a> </li>
                        <li><a href="#"> {{ links5 }}</a> </li>
                    </ul>
                </div>
            </b-col>
            <b-col md="3">
                <div class="Middle_content_body">
                    <ul>
                        <li><a href="#"> {{ links6 }} </a> </li>
                        <li><a href="#"> {{ links7 }}</a> </li>
                        <li><a href="#"> {{ links8 }}</a> </li>
                        <li><a href="#"> {{ links9 }}</a> </li>
                        <li><a href="#"> {{ links10 }}</a> </li>
                    </ul>
                </div>
            </b-col>
            <b-col md="3">
                <div class="Middle_content_body">
                    <ul>
                        <li><a href="#"> {{ links11 }} </a> </li>
                        <li><a href="#"> {{ links12 }}</a> </li>
                        <li><a href="#"> {{ links13 }}</a> </li>
                        <li><a href="#"> {{ links14 }}</a> </li>
                        <li><a href="#"> {{ links15 }}</a> </li>
                    </ul>
                </div>
            </b-col>
            <b-col md="3">
                <div class="Middle_content_body">
                    <ul>
                        <li><a href="#"> {{ links16 }} </a> </li>
                        <li><a href="#"> {{ links17 }}</a> </li>
                        <li><a href="#"> {{ links18 }}</a> </li>
                        <li><a href="#"> {{ links19 }}</a> </li>
                        <li><a href="#"> {{ links20 }}</a> </li>
                    </ul>
                </div>
            </b-col>
        </b-row>
    </b-container>
  </div>
</template>

<script>

import "./style.css";
export default {
  name: "MiddleContent",
  props: {
    greetingMessage: String,
    links1: String,
    links2: String,
    links3: String,
    links4: String,
    links5: String,
    links6: String,
    links7: String,
    links8: String,
    links9: String,
    links10: String,
    links11: String,
    links12: String,
    links13: String,
    links14: String,
    links15: String,
    links16: String,
    links17: String,
    links18: String,
    links19: String,
    links20: String
  },
};
</script>