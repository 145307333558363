<template>
  <div class="subscribe">
        <b-form @submit="onSubmitForm">
            <b-form-input v-model="email" placeholder="Type your email"></b-form-input>
            <b-button type="button" class="btn btn_subscribe" @click="handleSubsciber">
				<b-spinner v-if="loading"></b-spinner>
				<span v-else >Subscribe</span>
			</b-button>
			<p v-if="message != ''" class="error">{{message}}</p>
			<p v-if="successMessage != ''" class="success">{{successMessage}}</p>
        </b-form>
  </div>
</template>

<script>
import axios from "axios";
import "./style.css";
export default {
  name: "Subscribe",
  data(){
	return{
		email:'',
		message:'',
		successMessage:'',
		disabled:false,
		loading:false,
	}
  },
  methods: {
  validateEmail(email) 
	{
	 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
	  {
		return true
	  }
		
		return false
	},
	async handleSubsciber(){
		
		if(this.disabled == true){ return;}
		this.disabled = true;
		this.message = '';
		this.successMessage = '';
		if(this.email == ""){
			this.message = "Please fill required field!";
			this.disabled = false;
			return;
		}else if(this.email != '' && this.validateEmail(this.email) == false){
			this.message = "Please enter a valid email address!";
			this.disabled = false;
			return;
		}else{
			let formData = {
			 "person":{
				 "email":this.email,
				 "double_optin":1
				 }
			 };
			let obj = this;
			this.loading = true;
			await axios
			.post(process.env.VUE_APP_API_PATH+"/email/lists/f61e207463/subscriptions",formData)
			.then((response) => {
				if(response.data.success ==false){
					this.message = response.data.message;
				}else{
					this.successMessage = 'Please check your inbox to confirm your email address.';
					this.email = '';
				}
			}).catch((error)=>{
				this.message = error.message;
			});
			this.disabled = false;
			this.loading = false;
			
		}
	},
	onSubmitForm(event){
		event.preventDefault();
		this.handleSubsciber();
	
	}
  
  }
 
};
</script>
