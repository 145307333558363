<template>
  <div class="Singlejob">
    <b-container>
      <div class="Singlejob_bodypart">
        <div class="Single_toppart_center">
          <div v-if="fotoimage" class="logo_singlejob">
            <img v-if="fotoimage" :src="fotoimage" />
          </div>
          <div v-else class="logo_singlejob text_background">
            <span>{{ cname.substr(0, 1) }}</span>
          </div>
          <h3 class="company-job-title">
            <span class="company-name">{{ cname }} is hiring a</span>
            <span class="title-sec" v-if="title.toLowerCase().includes('web3')">{{ title }}</span>
            <span class="title-sec" v-else>Web3 {{ title }}</span>
          </h3>
          

          <p>
            <span v-if="salary_min">
              Compensation: ${{ salary_min }} - ${{ salary_max }}
            </span>
            <span v-else> </span>
          </p>

          <p>
            <span v-if="location"> ({{ location }}) </span>
            <span v-else></span>
          </p>

          <a :href="apply_url" class="btn btnapply_now">Apply Now</a>
        </div>

        <div class="Singlejob_middle_contaent" v-html="description">
          
        </div>

        <!-- <p class="seemore_details">
           See more <span> {{cname}} jobs </span>
        </p> -->

        <div class="HowAppyMain">
          <h3>How to Apply</h3>

          <div v-html="apply_text"></div>
          <a :href="apply_url" class="applyJob_btn">Apply for this job</a>

          <p class="centertext_note">
            <span><img src="../assets/arrowyellowcolor.svg"/></span> Please
            mention that you found the job on Natives. It helps get more
            companies to post their job here. Thanks!
          </p>

          <p class="whyplace_content">
            Genuine job applications should ALWAYS be free of charge and will
            NEVER require you to pay for equipment or training of any kind.
            Those that do are usually scams. Always verify that you are actually
            talking to the company in the job post. Be careful! When you click
            on the "Apply" button above, you will be forwarded to the job
            application page outside the Natives website. Natives cannot
            guarantee that the information on this page is 100% correct nor does
            it accept liability or responsbility for information hosted on
            external sites.
          </p>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import "./style.css";
export default {
  name: "Singlejob",
  props: {
    title: String,
    cname: String,
    salary_min: String,
    apply_url: String,
    salary_max: String,
    fotoimage: String,
    location: String,
    apply_text: String,
    remote: Boolean,
    description: String,
  },
};
</script>
