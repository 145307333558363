import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Jobdetails from '../views/Jobdetails.vue'
import Tags from '../views/Tags.vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import FlagIcon from 'vue-flag-icon'
import JsonCSV from 'vue-json-csv'
import VueClipboard from 'vue-clipboard2'
import VueEllipseProgress from 'vue-ellipse-progress';
import VueMeta from 'vue-meta'


Vue.use(VueEllipseProgress);
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(FlagIcon)

VueClipboard.config.autoSetContainer = true 
Vue.use(VueClipboard)
Vue.component('downloadCsv', JsonCSV)
Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/jobdetails',
    name: 'Jobdetails',
    component: Jobdetails
  },
  {
    path: '/:tags',
    name: 'tags',
    component: Tags
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
