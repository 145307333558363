<template>
  <div :class="innertags ? 'Tablefilter Tablefilter_tags' : 'Tablefilter'">
    <b-container>
      <b-row>
        <b-col lg="12">
          <b-table
            id="my-table"
            :fields="fields"
            :items="items"
            :select-mode="selectMode"
            responsive="sm"
            ref="selectableTable"
            small
            @row-clicked="onRowSelected"
			:tbody-tr-class="onRowClass"
          >
            <template #head(title)>
              <span class="text-info"> &nbsp; </span>
            </template>
            <template #head(company_logo_url)>
              <span class="text-info"> &nbsp; </span>
            </template>
            <template #head(tags)>
              <span class="text-info"> &nbsp; </span>
            </template>
            <template #head(location)>
              <span class="text-info"> &nbsp; </span>
            </template>
            <template #head(apply_url)>
              <span class="text-info"> &nbsp; </span>
            </template>
            <template #head(posted_at)>
              <span class="text-info"> &nbsp; </span>
            </template>
            <template #head(show_details)>
              <span class="text-info"> &nbsp; </span>
            </template>

            

            <template #cell(company_logo_url)="items">
              <div v-if="items.item.company_logo_url" class="table_joblogo">
                <img :src="items.item.company_logo_url" />
              </div>
              <div v-else class="table_joblogo text_background">
                <span>{{ items.item.company_name.substr(0, 1) }}</span>
              </div>
					
              <component :is="'style'" type="text/css" v-if="items.item.highlight">
				.row_{{items.item.id}}{ background-color:{{items.item.highlight_color}}; --bs-table-bg:{{items.item.highlight_color}} }
			  </component>
              <component :is="'script'" type="application/ld+json" v-if="items.item.salary_min === null">
                {
                           "@context": "https://schema.org",
                           "@type": "JobPosting",
                           "datePosted": "{{items.item.posted_at}}",
                           "description": "{{escape(items.item.description)}}",
                           "employmentType": "{{items.item.type}}",
                           "industry": "Startups",
                           "jobLocationType": "TELECOMMUTE",
                           "applicantLocationRequirements": {
                           "@type": "Country",
                           "name": "Anywhere"
                           },
                           "jobLocation": {
                           "address": {
                           "@type": "PostalAddress",
                           "addressCountry": "Anywhere",
                           "addressRegion": "Anywhere",
                           "streetAddress": "Anywhere",
                           "postalCode": "Anywhere",
                           "addressLocality": "Anywhere" }
                           },
                           "title": "{{items.item.title}}",
                           "image": "",
                           "occupationalCategory": "",
                           "workHours": "Flexible",
                           "validThrough": "{{items.item.valid_until}}",
                           "hiringOrganization": {
                           "@type": "Organization",
                           "name": "{{items.item.company_name}}",
                           "logo": ""
                           } }
              </component>
              <component :is="'script'" type="application/ld+json" v-else>
                {
                           "@context": "https://schema.org",
                           "@type": "JobPosting",
                           "datePosted": "{{items.item.posted_at}}",
                           "description": "{{escape(items.item.description)}}",
                           "baseSalary": {
                           "@type": "MonetaryAmount",
                           "currency": "USD",
                           "value": {
                           "@type": "QuantitativeValue",
                           "unitText": "YEAR",
                           "minValue": {{items.item.salary_min}},
                           "maxValue": {{items.item.salary_max}} }
                           },
                           "employmentType": "{{items.item.type}}",
                           "industry": "Startups",
                           "jobLocationType": "TELECOMMUTE",
                           "applicantLocationRequirements": {
                           "@type": "Country",
                           "name": "Anywhere"
                           },
                           "jobLocation": {
                           "address": {
                           "@type": "PostalAddress",
                           "addressCountry": "Anywhere",
                           "addressRegion": "Anywhere",
                           "streetAddress": "Anywhere",
                           "postalCode": "Anywhere",
                           "addressLocality": "Anywhere" }
                           },
                           "title": "{{items.item.title}}",
                           "image": "",
                           "occupationalCategory": "",
                           "workHours": "Flexible",
                           "validThrough": "{{items.item.valid_until}}",
                           "hiringOrganization": {
                           "@type": "Organization",
                           "name": "{{items.item.company_name}}",
                           "logo": ""
                           } }
              </component>
              <component :is="'script'" type="application/ld+json">
                {
                           "@context": "https://schema.org",
                           "@type": "Organization",
                           "name": "{{items.item.company_name}}
                           "
                           }
              </component>
            </template>

            <template #cell(title)="items">
              <div class="table_jobdetails">
                <h2 class="list-title">{{ items.item.title }}</h2>
                <p>{{ items.item.company_name }}</p>
                <p v-if="numFormatter(items.item.salary_min)" class="amoutlist">
                  ${{ numFormatter(items.item.salary_min) }} - ${{
                    numFormatter(items.item.salary_max)
                  }}
                </p>
                <!-- <p v-else > &nbsp; </p> -->
              </div>
            </template>

            <template #cell(tags)="items">
              <span class="remotedata">
                <ul class="tagsinnerlisting">
                  <li class="Tagslist_li" v-for="obj in items.item.tags.slice(0, 5)" :key="obj.id" @click="handleJobFilters"><b-link :href="convertToURL(obj.name)">{{ obj.name }}</b-link></li>
                </ul>
              </span>
            </template>

            <template #cell(posted_at)="items">
              {{
                time_ago(items.item.posted_at)
                  
              }}
            </template>

            <template #cell(apply_url)="items">
              <span class="applybutton_main">
                <a
                  :href="items.item.apply_url"
                  rel="nofollow"
                  target="_blank"
                  class="btn btn_apply"
                  >Apply</a
                >
              </span>
            </template>

            <template #cell(show_details)="row">
              <b-button size="sm" @click="row.toggleDetails" class="mr-2" :id="row.item.id">
                {{ row.detailsShowing ? "Hide" : "Show" }} Details
              </b-button>
            </template>

            <template #row-details="data">
              <Singlejob
                :title="data.item.title"
                :fotoimage="data.item.company_logo_url"
                :location="data.item.location"
                :description="data.item.description"
                :apply_text="data.item.apply_text"
                :remote="data.item.remote"
                :salary_min="numFormatter(data.item.salary_min)"
                :salary_max="numFormatter(data.item.salary_max)"
                :apply_url="data.item.apply_url"
                :cname="data.item.company_name"
                :highlight="data.item.highlight"
                :highlight_color="data.item.highlight_color"
              />
            </template>

          </b-table>

          <ul class="pagination b-pagination" v-if="itemsMeta.last_page > 1">
            <li
              v-bind:class="
                itemsMeta.current_page == 1 ? 'pageitem disabled' : 'pageitem'"
            >
              <span class="page-link" v-if="itemsMeta.current_page == 1"
                >&lt; Prev</span
              >
              <span
                class="page-link"
                v-else
                @click="getNextJobs(itemsMeta.current_page - 1)"
                >&lt; Prev</span
              >
            </li>
            <li
              class="pageitem"
              v-if="currentPage > 1 && itemsMeta.current_page >= currentPage"
              @click="getNextJobs(itemsMeta.current_page - 1)"
            >
              <button type="button" class="page-link">
                {{ itemsMeta.current_page - 1 }}
              </button>
            </li>
            <li
              v-bind:class="
                itemsMeta.current_page == currentPage
                  ? 'pageitem active'
                  : 'pageitem'"
            >
              <button type="button" class="page-link">
                {{ itemsMeta.current_page }}
              </button>
            </li>
            <li
              class="pageitem"
              v-if="itemsMeta.current_page < itemsMeta.last_page"
              @click="getNextJobs(itemsMeta.current_page + 1)"
            >
              <button type="button" class="page-link">
                {{ itemsMeta.current_page + 1 }}
              </button>
            </li>

    <li class="pageitem bullets"
              v-if=" itemsMeta.last_page > 2 && itemsMeta.current_page + 1 != itemsMeta.last_page &&
                  itemsMeta.current_page < itemsMeta.last_page"
            >
              <button type="button" class="page-link">...</button>
            </li>

            <li
              v-bind:class="
                itemsMeta.current_page == itemsMeta.last_page
                  ? 'pageitem last disabled'
                  : 'pageitem last'">
              <span
                class="page-link"
                v-if="itemsMeta.current_page == itemsMeta.last_page"
                >Next &gt;</span>
              <span
                class="page-link"
                v-else
                @click="getNextJobs(itemsMeta.current_page + 1)"
                >Next &gt;</span>
            </li>
          </ul>
           
          <div v-if="loader" class="loaderimage">
            <img src="../assets/loader_icon.png" />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>

 
</template>


<script>
import Singlejob from "@/components/Singlejob.vue";
import axios from "axios";
import "./style.css";
export default {
  props:{
    innertags:Boolean,
    checked:Boolean,
    page_size:Number,
	
  },
  name: "Tablefilter",
   
  components: {
    Singlejob,
  },
  data() {
    return {
      modes: ["single"],
	  tagsList:[],
	  tagsItem :[{key:'Analyst', value:'analyst'},
		{key:'Backend', value:'backend'},
		{key:'Blockchain', value:'blockchain'},
		{key:'Community Manager', value:'community-manager'},
		{key:'DAO', value:'dao'},
		{key:'DeFi', value:'defi'},
		{key:'Design', value:'design'},
		{key:'DevOps', value:'devops'},
		{key:'Discord', value:'discord'},
		{key:'Front End', value:'front-end'},
		{key:'Full Stack', value:'full-stack'},
		{key:'Intern', value:'intern'},
		{key:'Marketing', value:'marketing'},
		{key:'Moderator', value:'moderator'},
		{key:'NFT', value:'nft'},
		{key:'Product Manager', value:'product-manager'},
		{key:'Smart Contract', value:'smart-contract'},
		{key:'Solana', value:'solana'},
		{key:'Solidity', value:'solidity'},
		{key:'Web3js', value:'web3js'}
		],
      fields: [
        { key: "company_logo_url" },
        { key: "title", label: "Full Name" },
        "location",
        "tags",
        "posted_at",
        "apply_url",
        "show_details",
      ],
      perPage: 20,
      currentPage: 1,
      items: [],
      itemsMeta: { last_page: 1 },
      selectMode: "single",
      selected: [],
      cancelSource: null,
      loader: false,
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  watch:{
		$route (to, from){
			let pageNum = 1;
			if(typeof this.$route.query.page  !== "undefined"){
				pageNum = this.$route.query.page;
			}
			this.getJobs(pageNum);
			
		}
	},
  mounted() {
	let pageNum = 1;
	if(typeof this.$route.query.page  !== "undefined"){
		pageNum = this.$route.query.page;
	}
	this.getJobs(pageNum);
  },
  methods: {
	getNextJobs(pageNume){
		//console.log("this.$route",this.$route.path);
		let urlPath = this.$route.path;
		//alert(urlPath);
		if(urlPath == "/" && pageNume > 1){
			urlPath = "/web3-jobs";
		}else if(urlPath == "/web3-jobs" && pageNume == 1){
			urlPath = "/";
		}
		this.$router.push({ path: urlPath, query: { page: pageNume }});
		//this.getJobs(pageNume);
	},
    async getJobs(page = 1) {
		
      //this.cancelSearch();
      this.currentPage = page;
      this.loader = true;
      this.cancelSource = axios.CancelToken.source();
	 let  perPage = this.page_size;
	  if(page > 1){
		perPage = 35;
	  }	
	  let APIURL = process.env.VUE_APP_API_PATH+"/jobs?page_size="+perPage;
	  if(page > 1){
		  APIURL += "&page="+page;
	  }
	  let tagItem = [];
	  if(this.$route.path  != '/'){
		 let pathURL = this.$route.path;
		 pathURL=pathURL.replace("/","");
		 pathURL=pathURL.replace("remote-","");
		 pathURL=pathURL.replace("-web3-jobs","");
		 pathURL=pathURL.replace("web3-jobs","").split("+");
		 
		this.tagsList = [];
		pathURL.forEach(element =>{
			if(element != ""){
			if(pathURL.includes(element)){
				this.tagsList.push(element);
			}			
			tagItem.push(element);
			}
		});
		
	 }
	  if(tagItem.length > 0){
		APIURL += "&tags="+tagItem.join(",");
	  }
	  if(this.checked == true || (this.$route.path).includes("remote")){
		APIURL += "&remote=1";
	  }
	  
      await axios
        .get(APIURL, {
          cancelToken: this.cancelSource.token,
        })
        .then((response) => {
          this.items = response.data.data;
          this.itemsMeta = response.data.meta;
          response.data.meta['jobs'] = response.data.data;
          this.$emit("getJobsData", response.data.meta);
          //console.log("response", response.data);
          this.loader = false;
		  
		  
        });
    },
    handleOpen(url) {
      window.location = url;
    },
	// escape(htmlStr) {
	//    return htmlStr
  //     .replace(/&/g, "&amp;")
	// 		.replace(/</g, "&lt;")
	// 		.replace(/>/g, "&gt;")
	// 		.replace(/"/g, "&quot;")
	// 		.replace(/\//g, "\\");      
  // },
   escape (htmlStr) {
    if (typeof(htmlStr)!="string") return htmlStr;
    return htmlStr      
        .replace(/[\\]/g, '\\\\')
        .replace(/[\/]/g, '\\/')
        .replace(/[\b]/g, '\\b')
        .replace(/[\f]/g, '\\f')
        .replace(/[\n]/g, '\\n')
        .replace(/[\r]/g, '\\r')
        .replace(/[\t]/g, '\\t')
        .replace(/[\"]/g, '\\"')
        .replace(/\\'/g, "\\'"); 
},
	 handleJobFilters(event){
			event.preventDefault();
            let slugUrl  = this.convertToSlug(event.target.innerHTML);
			//this.slug = slugUrl;
			if(slugUrl.length > 0 && this.checked == true){
				slugUrl = "remote-"+slugUrl;
			}
			if(slugUrl.includes("remote-remote")){
				slugUrl = slugUrl.replace("remote-remote", "remote");
			}
			this.$router.push({ path: '/'+slugUrl+"-web3-jobs", query: { }})  ;
        },
		convertToSlug(Text) {
		  return Text.trim().toLowerCase()
					 .replace(/ /g, '-')
					 .replace(/[^\w-]+/g, '');
		},
	convertToURL(Text) {
		  let url =  "/";
		  if(this.checked == true){
			url = "remote-";
		  }
		  url +=Text.trim().toLowerCase()
					 .replace(/ /g, '-')
					 .replace(/[^\w-]+/g, '')+"-web3-jobs";
					  return url;
		},
  onRowClass(item) {
      if (item) {
        if (item.highlight == 1 && (item.highlight_color == '#F67372' || item.highlight_color == '#f67372')) {
          return 'table-danger'
        }
		else if(item.highlight == 1) {
			return "table-success row_"+item.id;
      }
      } 
    },
    numFormatter(num) {
      if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
      } else if (num > 1000000) {
        return (num / 1000000).toFixed(0) + "M"; // convert to M for number from > 1 million
      } else if (num < 900) {
        return num; // if value < 1000, nothing to do
      }
    },
    onRowSelected(item) { 
      document.getElementById(item.id).click();
    },
    time_ago(date) {
      var date = new Date(date);
	var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + "y";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + "mo";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + "d";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + "h";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + "m";
  }
  return Math.floor(seconds) + "s";
      return time;
    },
  },
};
</script>
