<template>
  <div class="Singlejob">
    <b-container>
      <div class="Singlejob_bodypart">

        <div class="Single_toppart_center">
            <div class="logo_singlejob">
                <img src="../assets/Job_ Logo1.png" />
            </div>
            <h2>Coinbase is hiring a</h2>
            <h3>Lead Project Manager</h3>
            <p>Compensation: <span> $60k - $80k </span> </p>
            <p>Remote</p>

            <b-button class="btn btnapply_now">Apply Now</b-button>
        </div>

        <div class="Singlejob_middle_contaent">
          <p>
          SideShift.ai, the no sign up crypto exchange is hiring a Junior Backend Engineer. Good job! 🎈
          </p>
          <p>
          Since launching in late 2018, SideShift.ai has been growing rapidly. With seven-figure daily volume and key industry partnerships, we have achieved a lot but there is still plenty more to do. To slot in with our stack we are looking for someone who has experience with TypeScript, PostgreSQL, Redis and GraphQL. We are searching for a junior backend engineer who is hungry to scale up their skillset and learn on the job.
          </p>
          <ul>
            <h3>Role</h3>
            <li>Design, build, and maintain SideShift.ai.</li>
            <li>Take ownership of certain aspects of the SideShift.ai backend.</li>
            <li>Ship. Your ideas will make it into live products in no time.</li>
          </ul>

          <ul>
            <h3>You</h3>
            <li> Junior level experience working with tools such as TypeScript, PostgreSQL, Redis and GraphQL.</li>
            <li>Hungry to learn and comfortable taking initiative.</li>
            <li>Strong interest in working on a cryptocurrency product.</li>
            <li>Able to join team meetings in EU/Asia timezones (but we try to avoid meetings as much as possible).</li>
          </ul>

          <ul>
            <h3>Nice to have</h3>
            <li>Experience with financial markets is a bonus but not necessary.</li>
            <li>EU/Asia timezone preferred.</li>
            <li>An appreciation for memes.</li>
          </ul>

         
          <ul>
            <h3>Perks</h3>
            <li>Salary paid in a SideShift.ai crypto of your choice.</li>
            <li>Join a profitable company with traction (seven figure weekly volume) and key industry partnerships with the likes of Trezor, Blockstream, Edge Wallet and more.</li>
            <li>Ship from the start. Your ideas actually lead to product developments with us.</li>
            <li>Remote-friendly - work wherever you feel most productive.</li>
            <li>Flexible vacation policy.</li>
          </ul>
          
          

        </div>

        <p class="seemore_details">
           See more <span> FTX jobs </span>
        </p>

        <div class="HowAppyMain">
          <h3>How to Apply</h3>
          <ul>
            <li>Click Apply (the fastest way to get your application reviewed) and fill in the form.</li>
            <li> You can check out/apply to some of our other positions here</li>
          </ul>
          <p>
            Please note, we'll do our best to review all applications. And we''ll try to do our best to answer everyone asap. If you really like the project and you'd like your application to be reviewed sooner, please consider creating some meaningful PR to any of the open source projects, or even to any other open source project that you like, and include the link to a PR. We give priority to these applications.
          </p>
        
           <a href="#" class="applyJob_btn">Apply for this job</a>


          <p class="centertext_note"> <span><img src="../assets/arrowyellowcolor.svg" /></span> Please mention that you found the job on Natives. It helps get more companies to post their job here. Thanks!</p>

          <p class="whyplace_content">
           Genuine job applications should ALWAYS be free of charge and will NEVER require you to pay for equipment or training of any kind. Those that do are usually scams. Always verify that you are actually talking to the company in the job post. Be careful! When you click on the "Apply" button above, you will be forwarded to the job application page outside the Natives website. Natives cannot guarantee that the information on this page is 100% correct nor does it accept liability or responsbility for information hosted on external sites. 
          </p>
        </div>

        </div>

    </b-container>

  </div>
</template>

<script>

import "./style.css";
export default {
  name: "Singlejobpage",
  props:{
	title:String
  }

};
</script>
