<template>
  <div id="app">
    <router-view @authenticated="setAuthenticated" />
  </div>
</template>

<script>
export default {
    name: 'App',
   data() {
        return {
            authenticated: false,
            mockAccount: {
                username: "ameyrupji",
                password: "P@ssword!"
            }
        }
    },
    methods: {
        setAuthenticated(status) {
            this.authenticated = status;
        },
        logout() {
            this.authenticated = false;
        }
    }
}
</script>
