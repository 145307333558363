<template>
  <div :class="innertags ? 'topbanner_tags' : 'topbanner'">
    <b-container >
        <b-row>
            <div class="bannerbg_image">
                <img src="../assets/Background_Antlers.png" />
            </div>
            <b-col lg="12">
               
                <div class="Topbanner_content">
                    <div v-if="bannerText != ''">
                    <h1 class="heading"> {{bannerText}} </h1>
                              <!-- <h3>The Web3 Revolution Belongs to the Brave</h3> -->
                              <p class="browserheading searchjob_bold">{{total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}} Jobs Found</p>
                    </div>
                    <div v-else>
                      <h1 class="heading">The Web3 Revolution Belongs to the Brave </h1>
                      <p class="browserheading">Browse {{total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}} jobs in Web3 at {{browseTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}} Web3 Projects</p>
					          </div>

                    <p class="topbanner_emails">
                      Receive 
                      <span class="selected_day">
                        <b-form-select v-model="selected" :options="options"></b-form-select>
                      </span> 
                      <span class="emailreminingtext">emails with new Web3 Jobs </span>
                    </p>
                    <div class="Subscribe_main">
                    <Subscribe />
                    </div>
                </div>
                
            </b-col>
        </b-row>
    </b-container>
  </div>

  
</template>

<script>

import Subscribe from '@/components/Subscribe.vue';
const axios = require('axios');
import "./style.css";
export  default {
	props:{
		bannerText:String,
		totalJob:Number,
		browseTotal:Number,
		total:Number,
    innertags:Boolean
	},
  name: "TopBanner",
  components: {
    Subscribe
  },
  data() {
      return {
        selected: null,
        options: [
          { value: null , text: 'Daily' },
          { value: 'b', text: 'Weekly' },
        ]
      }
    },


// methods: {
      // async sendDatas() {
      //       await this.$axios({
      //           method: 'get',
      //           url: 'http://api.natives.co/v1/jobs',
                
      //       })
      //           .then((response) => {
      //               console.log(response)
      //           })
      //           .catch((error) => {
      //               console.log(error)
      //           })
    // },
  // methods() {
   
  //      try{
  //        let response = await axios.post("http://api.natives.co/v1/jobs");
	// 		this.totalItems = response.data['keywords'];
  //      console.warn('jij',totalItems)
  //      }
      
    
  // }
  

};




</script>
